import React from 'react'
import { Link, graphql } from 'gatsby';
import { Layout, Spotlight, SEO, Testimonial } from '../components';

export const query = graphql`
  query {
    website: file(absolutePath: { regex: "/website.svg/" }) { publicURL },
    checkbox: file(absolutePath: { regex: "/survey.svg/" }) { publicURL },
    leicester: file(absolutePath: { regex: "/leicester.jpg/" }) { childImageSharp { fluid(maxHeight: 400, maxWidth: 900, cropFocus: SOUTH) { ...GatsbyImageSharpFluid_withWebp }}},
  }
`
const structuredData = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "name": "About",
  }]
}

const About = ({data}) => (
  <Layout headerBreakpoint={170}>
    <SEO title="About" structuredData={structuredData} />

    <Spotlight img={data.leicester} />

    <section>
      <h1>About Us</h1>

      <h2>A local family business</h2>

      <p>
        My name is Gary Parkinson and, as the director of G Parkinson Maintenance Ltd, I would firstly like to welcome you to our website. In the past and for many years, we have built a strong business mainly on the word of mouth of our customers. However, due to the consistent and considerable growth of our business, we thought it a good idea to get a website up and running so we can showcase the many services we offer and how we can help.
      </p>

      <p>
        In 2006, having completed many years within the maintenace industry (decorating, glazing, plastering, etc), I decided it was time to work for myself, and so became a Sole Trader. As a result of all my hard work and the dedication I put into the business, my customers happily recommended me and, sure enough, the business began to steadily grow.
      </p>

      <img src={data.website.publicURL} />

      <p>
        In 2017 G Parkinson Maintenance Ltd was born. This was because it was no longer feasible for me to carry on as a ‘Sole Trader’ due to the extent of growth my business had undergone over a period of eleven years. This transition was only made possible due to the continuation of great recommendations from my customers, and the high-quality work on offer and undertaken by an experienced and dedicated team.
      </p>

      <p>
        Despite this significant growth, we take great pride in the fact that we all continue to provide the same personal, friendly service that I myself did from day one. For confirmation of our customer recommendations, please take a look at our reviews on ‘Checkatrade’.
      </p>

      <a className="button secondary" href="https://www.checkatrade.com/trades/GParkinsonMaintenanceLtd/" target="_blank">Visit Our Reviews</a>
    </section>

    <Testimonial />

    <section variant="grey">
      <h1>How and where do we work?</h1>

      <h2>Closer than you think!</h2>

      <p>
        Being based in Leicester means that we are able to offer our services across the whole of the East Midlands region; and, even better, we are more than happy to provide a <strong>FREE no obligation quoting service</strong> and a competitive price for work required.
      </p>

      <p>
        Whilst it is possible that our quote may not be the lowest, we believe it is one that would be hard to beat if comparing like-for-like regarding service and quality.
      </p>

      <img src={data.checkbox.publicURL} />

      <Link to="/contact" className="button primary">Get A Free Quote</Link>
    </section>

    <section>
      <h1>Questions?</h1>

      <p>If you have any questions, please do not hesitate to contact us. We'd love to hear from you!</p>

      <Link to="/contact" className="button secondary">Contact Us</Link>
    </section>
  </Layout>
)

export default About
